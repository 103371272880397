import React from "react";
import Button from "@material-ui/core/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const { contact, canContactSubmit } = useStoreState((state) => state);
  const { contactErrors, contactDirty, progress, body, httpState } = contact;
  const { onContactFormChange, postContact } = useStoreActions((actions) => ({
    onContactFormChange: actions.onContactFormChange,
    postContact: actions.postContact,
  }));
  return (
    <form>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          value={body["email"]}
          onChange={(e) => handleInputChange(e, "email", onContactFormChange)}
          placeholder="exemple@pc27.dz"
        />
        {canShowErrors(contactDirty, contactErrors, "email") && (
          <div className="alert mt-1 alert-danger">
            Veuillez entrer une adresse mail valide.
          </div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone</label>
        <input
          type="phone"
          className="form-control"
          id="phone"
          value={body["phone"]}
          onChange={(e) => handleInputChange(e, "phone", onContactFormChange)}
          placeholder="ex : 0777888999"
        />
        {canShowErrors(contactDirty, contactErrors, "phone") && (
          <div className="alert mt-1 alert-danger">
            Veuillez entrer un numéro de téléphone valide.
          </div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="subject">Objet</label>
        <input
          type="text"
          className="form-control"
          id="subject"
          value={body["subject"]}
          onChange={(e) => handleInputChange(e, "subject", onContactFormChange)}
        />
        {canShowErrors(contactDirty, contactErrors, "subject") && (
          <div className="alert mt-1 alert-danger">
            Veuillez entrer "<i>l'objet de votre message</i>" entre <b>5</b> et{" "}
            <b>50</b> caractères.
          </div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="message">Message</label>
        <textarea
          type="text"
          className="form-control"
          id="message"
          value={body["message"]}
          onChange={(e) => handleInputChange(e, "message", onContactFormChange)}
          rows="4"
        />
        {canShowErrors(contactDirty, contactErrors, "message") && (
          <div className="alert mt-1 alert-danger">
            Veuillez entrer "<i>un message</i>" entre <b>5</b> et <b>255</b>{" "}
            caractères.
          </div>
        )}
      </div>
      <div className="form-group">
        <ReCAPTCHA
          sitekey="6LcgBfwUAAAAAFc1WN2P4GXVh-TobWdpT1ZpDCPD"
          onChange={(token) => {
            onContactFormChange({ key: "captcha", value: token });
          }}
        />
      </div>
      <div className="d-flex">
        <Button
          className="ml-auto"
          color="primary"
          variant="contained"
          onClick={async () => {
            await postContact();
          }}
          disabled={!canContactSubmit || progress}
        >
          Envoyer
        </Button>
      </div>
      {showAlert(httpState)}
    </form>
  );
};

const showAlert = (state) => {
  if (state === "ERROR")
    return (
      <div className="alert alert-danger mt-2">
        Une érreur s'est produite, veuillez réessayer
      </div>
    );
  if (state === "SUCCESS")
    return (
      <div className="alert alert-success mt-2">
        Votre email requete a été bien envoyé, notre commercial va vos contacter
        dans le plus bref délait.
      </div>
    );

  return <React.Fragment />;
};

const handleInputChange = (e, key, action) => {
  e.persist();
  action({ key, value: e.target.value });
};

const canShowErrors = (dirty, errors, key) => {
  return dirty[key] && errors[key];
};

export default ContactForm;
