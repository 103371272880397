import React, { useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Badge from "@material-ui/core/Badge";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import {
  faAt,
  faPhoneAlt,
  faShoppingBasket,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import pc27 from "../assets/pc27.png";

const menu = [
  { display: "Accueil", path: "/" },
  // { display: "Produits", path: "/products" },
  { display: "Services", path: "/services" },
  { display: "Horaires d'ouverture", path: "/opening-hours" },
  { display: "Conctact", path: "/contact" },
];

const Navbar = () => {
  const { order, http, filter, collapse, showHeader } = useStoreState(
    (state) => state
  );
  const {
    fetchCategorie,
    onFilterChange,
    onMenuToggled,
    setScrollPosition,
  } = useStoreActions((actions) => ({
    fetchCategorie: actions.fetchCategorie,
    onFilterChange: actions.onFilterChange,
    onMenuToggled: actions.onMenuToggled,
    setScrollPosition: actions.setScrollPosition,
  }));

  const { items } = order.body;
  const { categories } = http;

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => handleScroll(setScrollPosition),
      true
    );
    fetchCategorie();
    onFilterChange({ key: "category", value: params["category"] || "all" });
    onFilterChange({ key: "search", value: params["search"] || "" });
    // eslint-disable-next-line
  }, []);
  return (
    <div className="app-navbar">
      {showHeader && (
        <header className="p-5 navbar-fixed-top">
          <div className="container d-flex align-items-center">
            <img className="pc27-logo" src={pc27} alt="www.pc27.dz" />
            <div className="input-group d-flex mx-5 rounder-0">
              <select
                className="form-control rounded-0"
                value={filter["category"] || "all"}
                onChange={(e) =>
                  onFilterChange({ key: "category", value: e.target.value })
                }
              >
                {categories.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.category}
                  </option>
                ))}
              </select>
              <input
                type="text"
                className="form-control rounded-0"
                placeholder="Recherche"
                value={filter["search"] || ""}
                onChange={(e) =>
                  onFilterChange({ key: "search", value: e.target.value })
                }
                aria-label="Amount (to the nearest dollar)"
              />
              <div className="input-group-append rounded-0">
                <span className="input-group-text rounded-0 w-100 justify-content-center">
                  <NavLink
                    to={`/?${Object.keys(filter)
                      .map((key) => `${key}=${filter[key]}`)
                      .join("&")}`}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </NavLink>
                </span>
              </div>
            </div>
            <div className="contact">
              <button
                onClick={() => {
                  window.open("mailto:commercial@pc27services.com");
                }}
                className="justify-content-center d-flex align-items-center btn btn-link pc27-cl-blue"
              >
                <FontAwesomeIcon className="mr-2" icon={faAt} />
                commercial@pc27services.com
              </button>
              <button className="justify-content-center d-flex align-items-center btn btn-link pc27-cl-blue">
                <FontAwesomeIcon className="mr-2" icon={faPhoneAlt} />
                <span
                  onClick={() => {
                    window.open("tel:0795 00 27 27");
                  }}
                >
                  0795 00 27 27
                </span>
                /{" "}
                <span
                  onClick={() => {
                    window.open("tel:045 35 14 41");
                  }}
                >
                  045 35 14 41
                </span>
              </button>
            </div>
          </div>
        </header>
      )}
      <nav className="navbar navbar-expand-lg navbar-dark pc27-bg-blue">
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => onMenuToggled()}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`${collapse} navbar-collapse`} id="navbarNav">
          <ul className="navbar-nav text-center">
            {menu.map(({ display, path }, index) => (
              <li className="nav-item" key={index}>
                <NavLink
                  to={path}
                  activeStyle={{ color: "white" }}
                  className="nav-link mx-2"
                >
                  {display}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item text-center">
              <NavLink to="/products/shopping" className="nav-link mx-2">
                Mon panier
                <Badge
                  className="ml-2"
                  badgeContent={items.length}
                  color="secondary"
                >
                  <FontAwesomeIcon color="white" icon={faShoppingBasket} />
                </Badge>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

const handleScroll = (handleScroll) => {
  handleScroll(window.scrollY);
};

export default Navbar;
