import React from "react";
import OrderForm from "../components/order.form";
import { useStoreActions, useStoreState } from "easy-peasy";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ProductHorizontal from "../components/product.horizontal.item";
import Button from "@material-ui/core/Button";
import sad from "../assets/sad.svg";
import queryString from "query-string";

// import sigma from "../assets/sigma.svg";

const ShoppingPage = (props) => {
  const { order, canSubmit } = useStoreState((state) => state);
  const { currentStep } = order;
  const { items } = order.body;
  const {
    removeFromList,
    addToList,
    decrementQuantity,
    nextStep,
    previousStep,
    postNewOrder,
    refreshOrder,
  } = useStoreActions((actions) => ({
    addToList: actions.addToList,
    removeFromList: actions.removeFromList,
    decrementQuantity: actions.decrementQuantity,
    nextStep: actions.nextStep,
    previousStep: actions.previousStep,
    postNewOrder: actions.postNewOrder,
    refreshOrder: actions.refreshOrder,
  }));

  const { history, location } = props;
  const params = queryString.parse(location.search);
  const { refresh } = params;

  if (refresh !== "true") {
    refreshOrder();
    history.push("/products/shopping?refresh=true");
  }
  return (
    <div className="container py-5">
      <Stepper>
        <Step>
          <StepLabel>Liste produits</StepLabel>
        </Step>
        <Step>
          <StepLabel>Information client</StepLabel>
        </Step>
      </Stepper>
      <div className="step-content">
        {currentStep === 0
          ? productsListTemplate(
              items,
              addToList,
              removeFromList,
              decrementQuantity,
              currentStep,
              nextStep,
              previousStep
            )
          : clientFormTemplate(props.history)}
      </div>

      <div className="w-100 mt-2 p-3 bg-white justify-content-end">
        <div className="d-flex align-items-center">
          <Button disabled={currentStep === 0} onClick={() => previousStep()}>
            Retour
          </Button>
          <Button
            disabled={items.length === 0 || (!canSubmit && currentStep === 1)}
            className="ml-auto"
            color={currentStep === 0 ? "default" : "primary"}
            variant={currentStep === 0 ? "text" : "contained"}
            onClick={() =>
              handleNextButton(currentStep, nextStep, postNewOrder, history)
            }
          >
            {currentStep === 0 ? "Suivant" : "Commander"}
          </Button>
        </div>
      </div>
    </div>
  );
};

const productsListTemplate = (
  items,
  addToList,
  removeFromList,
  decrementQuantity
) => {
  if (items.length === 0)
    return (
      <div className="text-center p-5 bg-white">
        <img src={sad} height="50" alt="sad" width="50" className="mb-2" />
        <div className="lead">
          Aucun produit n'a été trouver dans votre liste
        </div>
      </div>
    );

  return (
    <React.Fragment>
      <div className="product-list mt-2">
        {items.map((item, index) => (
          <ProductHorizontal
            key={index}
            {...item}
            onDecrementClicked={() => decrementQuantity(item.productId)}
            onIncrementClicked={() => addToList(item)}
            onDeleteClicked={() => removeFromList(item.productId)}
          />
        ))}
      </div>
      <div className="bg-white d-flex">
        <div className="ml-auto p-3 d-flex align-items-center">
          {/* <img className="ml-auto" src={sigma} height="18" alt="Total" /> */}
          <div className="h5 mt-1">
            <b className="mx-2">
              {items.reduce((a, b) => {
                return a + b.price * b.quantity;
              }, 0)}
            </b>
            DZD
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const clientFormTemplate = (history) => {
  return <OrderForm history={history} />;
};

const handleNextButton = (currentStep, nextStep, postNewOrder, history) => {
  if (currentStep === 0) {
    nextStep();
    return;
  }

  // do http post and upload data
  postNewOrder(history);
};

export default ShoppingPage;
