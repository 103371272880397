import React from "react";
import ContactForm from "../components/contact.form";

const ContactPage = () => {
  return (
    <div className="container my-5 py-4 bg-white">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-sm-12 col-sm-12">
          <div className="h4  my-5">Contactez nous</div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
