import React from "react";
import Product from "./product.item";
import { useStoreActions, useStoreState } from "easy-peasy";

const ProductsList = ({ navigateToDetail }, ref) => {
  const { http, order } = useStoreState((state) => state);
  const addToList = useStoreActions((actions) => actions.addToList);
  const { items } = order.body;
  const { products, loadingProducts } = http;
  return (
    <div className="row">
      {loadingProducts &&
        [1, 2, 3, 4, 5, 6].map((index) => {
          return progressTemplate(index);
        })}
      {products &&
        Array.isArray(products) &&
        products.map((item, index) => {
          return (
            <div key={index} className="col-lg-4 col-md-6 col-12 mb-2">
              <Product
                product={item}
                items={items}
                addToList={addToList}
                onDetailClicked={(id) => navigateToDetail(id)}
              />
            </div>
          );
        })}
      {(!products || products.length === 0) && loadingProducts === false && (
        <div className="col-12">
          <div className="jumbotron text-center">
            <h1 className="display-4">Aucun produit trouvé !!</h1>
          </div>
        </div>
      )}
    </div>
  );
};

const progressTemplate = (index) => (
  <div key={index} className="col-lg-4 col-md-6 col-12 mb-2">
    <div className="w-100 bg-white h-100 d-inline-block border border-light rounded-sm  p-2">
      <div
        style={{
          height: "200px",
        }}
        className="w-100 bg-progress"
      ></div>
      <div
        style={{
          height: "50px",
        }}
        className="w-100 mt-2 bg-progress"
      ></div>
    </div>
  </div>
);

export default ProductsList;
