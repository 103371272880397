import { createStore, action, computed, thunk } from "easy-peasy";
import Joi from "joi-browser";
import productHttpService from "../services/products.services";
import emailHttpService from "../services/about.services";

const contactSchema = {
  email: Joi.string().email().required(),
  phone: Joi.string().min(10).required(),
  subject: Joi.string().min(4).max(50).required(),
  message: Joi.string().min(5).max(255).required(),
  captcha: Joi.string().required(),
};

const orderSchema = {
  firstname: Joi.string().min(4).max(50).required(),
  familyname: Joi.string().min(4).max(50).required(),
  email: Joi.string().email().required(),
  phone: Joi.string().min(10).required(),
  address: Joi.string().min(5).max(255).required(),
  currentStep: Joi.number(),
  items: Joi.array(),
  captcha: Joi.string().required(),
};

const initializeOrder = () => {
  if (
    localStorage.getItem(orderKey) &&
    JSON.parse(localStorage.getItem(orderKey))["body"]
  )
    return JSON.parse(localStorage.getItem(orderKey));

  localStorage.removeItem(orderKey);
  return {
    body: {
      items: [],
      firstname: "",
      familyname: "",
      email: "",
      phone: "",
      address: "",
      captcha: null,
    },
    currentStep: 0,
    httpState: "",
    progress: false,
  };
};

const orderKey = "order";
const order = initializeOrder();

const updateLocalStorage = (order) => {
  localStorage.setItem(orderKey, JSON.stringify(order));
};

const previousStep = action((state, id) => {
  state.order.currentStep--;
  updateLocalStorage(state.order);
});

const nextStep = action((state, id) => {
  state.order.currentStep++;
  updateLocalStorage(state.order);
});

const decrementQuantity = action((state, id) => {
  const index = state.order.body.items.findIndex((elt) => elt.productId === id);

  if (index >= 0) state.order.body.items[index].quantity--;
  updateLocalStorage(state.order);
});

const addToList = action((state, item) => {
  const index = state.order.body.items.findIndex(
    (elt) => elt.productId === item.productId
  );
  // does not exist
  if (index < 0) state.order.body.items.push({ quantity: 1, ...item });
  else state.order.body.items[index].quantity++;
  updateLocalStorage(state.order);
});

const removeFromList = action((state, id) => {
  state.order.body.items = [
    ...state.order.body.items.filter(({ productId }) => productId !== id),
  ];
  updateLocalStorage(state.order);
});

const selectImage = action((state, index) => {
  state.selectedImg = index;
});

const onFormChange = action((state, { key, value }) => {
  state.order.body[key] = value;
  state.dirty[key] = true;
  const result = Joi.validate(state.order.body, orderSchema);
  if (!result.error) {
    state.errors = {};
  } else {
    const errors = {};
    const { details } = result.error;
    details.forEach((element) => {
      if (state.dirty[element.path]) {
        errors[element.path] = element.message;
      }
    });
    state.errors = errors;
  }
  updateLocalStorage(state.order);
});

const onContactFormChange = action((state, { key, value }) => {
  state.contact.body[key] = value;
  state.contact.contactDirty[key] = true;
  const result = Joi.validate(state.contact.body, contactSchema);

  if (!result.error) {
    state.contact.contactErrors = {};
  } else {
    const errors = {};
    const { details } = result.error;
    details.forEach((element) => {
      if (state.contact.contactDirty[element.path]) {
        errors[element.path] = element.message;
      }
    });
    state.contact.contactErrors = errors;
  }
});

const fetchProducts = thunk(async (actions, params) => {
  try {
    actions.toggleProgress("loadingProducts");
    actions.pushHttpData({ data: [], key: "products" });

    var categories = store.getState().http.categories;
    if (categories.length === 0) {
      actions.toggleProgress("loadingCategories");
      const cats = await productHttpService.getCategories();

      actions.pushHttpData({
        data: [
          { category: "Tout les produits", id: "all", parent: "0" },
          ...cats,
        ],
        key: "categories",
      });
    }
    const { data, canGoNext, max, min } = await productHttpService.getProducts({
      ...params,
    });
    setTimeout(() => {
      if (store.getState().http.loadingCategories)
        actions.toggleProgress("loadingCategories");
      actions.toggleProgress("loadingProducts");

      actions.pushHttpData({ data, key: "products", canGoNext, min, max });
    }, 1000);
  } catch (error) {
    actions.pushHttpData({ data: [], key: "products" });
  }
});

const toggleProgress = action((state, key) => {
  state.http[key] = !state.http[key];
});

const pushHttpData = action(
  (state, { data, key, canGoNext = false, min = 0, max = 0 }) => {
    if (canGoNext) state.http.canGoNext = canGoNext;
    state.http[key] = data;
    state.http["min"] = min;
    state.http["max"] = max;
  }
);

const postContact = thunk(async (actions) => {
  try {
    actions.toggleProgressByKey({ key: "contact", value: true });
    const contact = store.getState().contact.body;
    const result = await emailHttpService.postContact(contact);
    actions.toggleProgressByKey({ key: "contact", value: false });
    if (result === true) {
      actions.clearContactForm();
    }

    actions.showHttpResult({
      value: result === true ? "SUCCESS" : "ERROR",
      key: "contact",
    });
    setTimeout(() => {
      actions.showHttpResult({ value: "", key: "contact" });
    }, 5000);
  } catch (error) {
    actions.toggleProgressByKey({ key: "contact", value: false });
  }
});

const clearContactForm = action((state) => {
  state.contact.body = {
    email: "",
    phone: "",
    subject: "",
    message: "",
    captcha: "",
  };
  state.contact.contactErrors = {};
  state.contact.contactDirty = {};
});

const toggleProgressByKey = action((state, { key, value }) => {
  state[key].progress = value;
});

const showHttpResult = action((state, { key, value }) => {
  state[key].httpState = value;
});

const fetchDetail = thunk(async (actions, id) => {
  try {
    actions.toggleProgress("loadingDetails");
    actions.pushHttpData({ data: {}, key: "product" });

    const { data } = await productHttpService.getProductById(id);
    setTimeout(() => {
      actions.toggleProgress("loadingDetails");
      actions.pushHttpData({ data, key: "product" });
    }, 1000);
  } catch (error) {
    actions.pushHttpData({ data: {}, key: "product" });
    actions.toggleProgress("loadingDetails");
  }
});

const postNewOrder = thunk(async (actions, history) => {
  try {
    actions.toggleProgressByKey({ key: "order", value: true });
    const order = store.getState().order.body;
    const result = await emailHttpService.postOrder(order);
    console.log(result);
    actions.toggleProgressByKey({ key: "order", value: false });

    actions.showHttpResult({
      value: result ? "SUCCESS" : "ERROR",
      key: "order",
    });

    if (!result) {
      setTimeout(() => {
        actions.showHttpResult({ value: "", key: "order" });
      }, 5000);
    } else {
      actions.clearOrder();
      history.push("/?success=true");
    }
  } catch (error) {
    actions.toggleProgressByKey({ key: "order", value: false });
  }
});

const fetchCategorie = thunk(async (actions) => {
  try {
    var { categories, loadingCategories } = store.getState().http;
    if (categories.length === 0 && !loadingCategories) {
      actions.toggleProgress("loadingCategories");
      const cats = await productHttpService.getCategories();
      actions.pushHttpData({
        data: [{ category: "Tout les produits", id: "all" }, ...cats["data"]],
        key: "categories",
      });
    }
  } catch (error) {
    actions.pushHttpData({
      data: [{ category: "Tout les produits", id: "all" }],
      key: "categories",
    });
  }
});

const clearOrder = action((state) => {
  state.order = {
    body: {
      items: [],
      firstname: "",
      familyname: "",
      email: "",
      phone: "",
      address: "",
      captcha: null,
    },
    currentStep: 0,
    httpState: "",
    progress: false,
  };
  updateLocalStorage(state.order);
});

const onFilterChange = action((state, { key, value }) => {
  state.filter[key] = value;
});

const onMenuToggled = action((state) => {
  state.collapse = state.collapse === "collapse" ? "" : "collapse";
});

const showHideDialog = action((state, value) => {
  state.filterDialog = value;
});

const setScrollPosition = action((state, position) => {
  state.scrollY = position;
});

const setCategoryExpendedState = action((state, { key, value }) => {
  state.categoriesExpended[key] = value;
});

const refreshOrder = action((state) => {
  state.order = initializeOrder();
});

const store = createStore({
  // states
  http: {
    product: {},
    loadingDetails: false,
    products: [],
    min: 0,
    max: 1000000,
    loadingProducts: false,
    canGoNext: false,
    categories: [],
    loadingCategories: false,
  },
  categoriesExpended: {},
  collapse: "collapse",
  filterDialog: false,
  order,
  selectedImg: 0,
  errors: {},
  dirty: {},
  contact: {
    body: { email: "", phone: "", subject: "", message: "", captcha: "" },
    contactErrors: {},
    contactDirty: {},
    progress: false,
    httpState: "",
  },
  filter: {},
  canSubmit: computed((state) => {
    const result = Joi.validate(state.order.body, orderSchema);
    return !result.error && !state.order.progress;
  }),
  canContactSubmit: computed((state) => {
    const result = Joi.validate(state.contact.body, contactSchema);
    return result.error === null || result.error === undefined;
  }),
  scrollY: 0,
  showHeader: computed(({ scrollY }) => {
    return scrollY <= 50;
  }),
  // thunks
  fetchProducts,
  postContact,
  fetchDetail,
  postNewOrder,
  fetchCategorie,
  // actions
  pushHttpData,
  toggleProgress,
  nextStep,
  previousStep,
  decrementQuantity,
  addToList,
  removeFromList,
  selectImage,
  onFormChange,
  onContactFormChange,
  toggleProgressByKey,
  clearContactForm,
  showHttpResult,
  clearOrder,
  onFilterChange,
  onMenuToggled,
  showHideDialog,
  setScrollPosition,
  setCategoryExpendedState,
  refreshOrder,
});

export default store;
