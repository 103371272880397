import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {
  Delete,
  AddCircleOutline,
  RemoveCircleOutline,
} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ProductHorizontal = ({
  photo,
  title,
  price,
  quantity,
  onIncrementClicked,
  onDeleteClicked,
  onDecrementClicked,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className="w-100 horizental-card bg-white d-flex align-items-center my-2 p-3">
        <img src={photo} alt={title} />
        <div className="w-100 d-flex flex-column">
          <div className="h6">{title}</div>
          <span>
            <b>{price}</b> DZD X <b>{quantity}</b> PC(s)
          </span>
          <div className="align-self-end">
            <IconButton
              color="secondary"
              onClick={() => {
                if (quantity - 1 === 0) handleClickOpen();
                else onDecrementClicked();
              }}
            >
              <RemoveCircleOutline />
            </IconButton>
            <IconButton
              className="mx-2"
              color="primary"
              onClick={() => onIncrementClicked()}
            >
              <AddCircleOutline />
            </IconButton>
            <IconButton color="secondary" onClick={handleClickOpen}>
              <Delete />
            </IconButton>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Supprimer de la liste"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Voulez vous vraiment supprimer ce produit de votre liste ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
            <Button
              onClick={() => {
                handleClose();
                onDeleteClicked();
              }}
              color="secondary"
              autoFocus
            >
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
};
export default ProductHorizontal;
