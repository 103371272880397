import React from "react";
import Button from "@material-ui/core/Button";
import repare_hardware from "../assets/repare_hardware-min.jpg";
import camera from "../assets/surveillance_camera-min.jpg";
import networking from "../assets/networking-min.jpg";

const ServicesPage = (props) => {
  console.log(props);
  const { history } = props;
  return (
    <React.Fragment>
      <div
        className="justify-content-center d-flex align-items-center"
        style={getStyle(repare_hardware)}
      >
        <div className="h1 w-100 text-center opacity-50 pc27-cl-blue p-5">
          <b>Réparation matériel informatique</b>
        </div>
      </div>
      <div className="container py-5">
        <p className="text-justify">
          no purse as fully me or point. Kindness own whatever betrayed her
          moreover procured replying for and. Proposal indulged no do do
          sociable he throwing settling. Covered ten nor comfort offices
          carried. Age she way earnestly the fulfilled extremely. Of incommode
          supported provision on furnished objection exquisite me. Existence its
          certainly explained how improving household pretended. Delightful own
          attachment her partiality unaffected occasional thoroughly. Adieus it
          no wonder spirit houses.
        </p>
        <Button
          onClick={() => {
            history.push("/contact");
          }}
          className="d-block ml-auto"
          variant="outlined"
          color="primary"
        >
          Contactez-Nous
        </Button>
      </div>
      <div
        className="justify-content-center d-flex align-items-center"
        style={getStyle(camera)}
      >
        <div className="h1 w-100 text-center opacity-50 pc27-cl-blue p-5">
          <b>Caméra de surveillance</b>
        </div>
      </div>
      <div className="container py-5">
        <p className="text-justify">
          no purse as fully me or point. Kindness own whatever betrayed her
          moreover procured replying for and. Proposal indulged no do do
          sociable he throwing settling. Covered ten nor comfort offices
          carried. Age she way earnestly the fulfilled extremely. Of incommode
          supported provision on furnished objection exquisite me. Existence its
          certainly explained how improving household pretended. Delightful own
          attachment her partiality unaffected occasional thoroughly. Adieus it
          no wonder spirit houses.
        </p>
        <Button
          onClick={() => {
            history.push("/contact");
          }}
          className="d-block ml-auto"
          variant="outlined"
          color="primary"
        >
          Contactez-Nous
        </Button>
      </div>
      <div
        className="justify-content-center d-flex align-items-center"
        style={getStyle(networking)}
      >
        <div className="h1 w-100 text-center opacity-50 pc27-cl-blue p-5">
          <b>Réseaux & connectivité</b>
        </div>
      </div>
      <div className="container py-5">
        <p className="text-justify">
          no purse as fully me or point. Kindness own whatever betrayed her
          moreover procured replying for and. Proposal indulged no do do
          sociable he throwing settling. Covered ten nor comfort offices
          carried. Age she way earnestly the fulfilled extremely. Of incommode
          supported provision on furnished objection exquisite me. Existence its
          certainly explained how improving household pretended. Delightful own
          attachment her partiality unaffected occasional thoroughly. Adieus it
          no wonder spirit houses.
        </p>
        <Button
          onClick={() => {
            history.push("/contact");
          }}
          className="d-block ml-auto"
          variant="outlined"
          color="primary"
        >
          Contactez-Nous
        </Button>
      </div>
    </React.Fragment>
  );
};

const getStyle = (img) => {
  return {
    backgroundImage: `url(${img})`,
    height: "400px",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionY: "bottom",
  };
};

export default ServicesPage;
