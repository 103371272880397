import React, { useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import CurrencyFormat from "react-currency-format";

const DetailPage = (props) => {
  var descriptionRef = useRef(null);

  const { http, order } = useStoreState((state) => state);

  const { product, loadingDetails } = http;
  const {
    id,
    title,
    slidePictures,
    photo,
    img_path,
    promo,
    price,
    promoPrice,
    description,
    attribute,
    soldOut,
  } = product;

  const { addToList, selectImage, fetchDetail } = useStoreActions(
    (actions) => ({
      addToList: actions.addToList,
      selectImage: actions.selectImage,
      fetchDetail: actions.fetchDetail,
    })
  );
  const { match, history } = props;

  useEffect(() => {
    fetchDetail(match.params["productId"]);
    // eslint-disable-next-line
  }, Object.values(match.params));

  const selectedImg = useStoreState((state) => state.selectedImg);
  return (
    <div className="container-fluid py-5">
      {Object.keys(product).length > 0 && !loadingDetails ? (
        <div className="row bg-white justify-content-center pb-3">
          <div className="col-md-6 p-2">
            <div className="selected-picture w-100">
              <img
                alt={title}
                src={`${img_path}${slidePictures[selectedImg]}`}
                className="d-block mx-auto"
              />
            </div>
            <div className="image-list d-block mx-auto py-2 d-flex align-items-start">
              {slidePictures.map((image, index) => {
                return (
                  <img
                    className="mx-1 img-thumbnail"
                    onClick={() => {
                      selectImage(index);
                    }}
                    key={index}
                    src={`${img_path}${image}`}
                    alt={title}
                  />
                );
              })}
            </div>
          </div>
          <div className="col-md-6 p-5">
            <div className="h4">{title}</div>
            {(+price !== 0 || +promoPrice !== 0) && (
              <div className="price-holder">
                <span
                  className={
                    promo === "1"
                      ? "ml-1 promo-theme text-secondary"
                      : "pc27-cl-blue h4"
                  }
                >
                  <CurrencyFormat
                    value={price}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  DZD
                </span>
                <br />
                {promo === "1" && (
                  <React.Fragment>
                    <span className="pc27-cl-blue h4">
                      <CurrencyFormat
                        value={promoPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                      />{" "}
                      DZD
                    </span>
                  </React.Fragment>
                )}
              </div>
            )}
            {+soldOut === 1 && (
              <span className="pc27-cl-red font-weight-bold">Stock Epuisé</span>
            )}
            {description !== "" && (
              <div className="short-decsription text-justify my-2">
                {description.length < 200
                  ? description
                  : description.substring(0, 199) + "..."}
                <br />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => scrollToRef(descriptionRef)}
                    color="primary"
                  >
                    Voir la description complette
                  </Button>
                </div>
              </div>
            )}
            <div className="d-flex flex-column actions-container">
              <Button
                onClick={() =>
                  addToShopList(addToList, {
                    id,
                    price,
                    promo,
                    promoPrice,
                    title,
                    photo: `${img_path}${photo}`,
                  })
                }
                className="mb-2"
                variant="outlined"
                color="primary"
              >
                Ajouter au panier
              </Button>
              <Button
                onClick={() => {
                  const items = order.body.items || [];
                  const index = items.findIndex((elt) => elt.productId === id);
                  if (index === -1) {
                    addToShopList(addToList, {
                      id,
                      price,
                      promo,
                      promoPrice,
                      title,
                      photo: `${img_path}${photo}`,
                    });
                  }
                  history.push("/products/shopping");
                }}
                variant="outlined"
                color="secondary"
              >
                Commander
              </Button>
            </div>
          </div>
          {description !== "" && fullDescTemplate(description, descriptionRef)}
          {JSON.parse(attribute).length > 0 && attributeTemplate(attribute)}
        </div>
      ) : loadingDetails ? (
        <div className="row">
          {[12, 12, 12].map((elt, index) => {
            return (
              <div key={index} className={`col-sm-${elt} col-12 mb-2`}>
                <div className="w-100 bg-white h-100 d-inline-block border border-light rounded-sm ">
                  <div
                    style={{
                      height: "250px",
                    }}
                    className="w-100 bg-progress"
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <React.Fragment>
          <div className="display-2 text-center">404</div>
          <div className="display-4 text-center">Produit Introuvable</div>
        </React.Fragment>
      )}
    </div>
  );
};

const attributeTemplate = (attribute) => {
  var attributes = JSON.parse(attribute);
  return (
    <React.Fragment>
      <div className="col-md-12 px-5">
        <div className="h5 mt-5">Fiche technique :</div>
      </div>
      <div className="col-md-8 pt-2">
        <table className="table">
          <tbody>
            {attributes.map((attr, index) => {
              return (
                <tr key={index}>
                  <td>{attr["inputLabel"]}</td>
                  <td>{attr["inputValue"]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

const fullDescTemplate = (description, descriptionRef) => {
  return (
    <div className="col-md-12 pt-5 px-5" ref={descriptionRef}>
      <div className="h5">Description du produit :</div>
      <div className="text-justify">{description}</div>
    </div>
  );
};

const addToShopList = (
  addToList,
  { id, price, promo, promoPrice, title, photo }
) => {
  addToList({
    productId: id,
    price: promo === "1" ? promoPrice : price,
    title,
    photo,
  });
};

const scrollToRef = (descriptionRef) => {
  try {
    window.scrollTo(0, descriptionRef.current.offsetTop);
  } catch (error) {
    console.log(error);
  }
};

export default DetailPage;
