import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import appStore from "./models/appStore";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import ProductsPage from "./pages/products.page";
import ProductDetailPage from "./pages/detail.page";
import ShoppingPage from "./pages/shopping.page";
// import HomePage from "./pages/home.page";
import ServicesPage from "./pages/services.page";
import ContactPage from "./pages/contact.page";
import OpeningHoursPage from "./pages/opening-hours.page";

function App() {
  return (
    <StoreProvider store={appStore}>
      <Router>
        <div className="app">
          <Navbar />
          <main className="app-container pb-3">
            <Switch>
              <Route
                path="/products/details/:productId"
                component={(props) => <ProductDetailPage {...props} />}
              />
              <Route
                path="/products/shopping"
                excat
                component={(props) => <ShoppingPage {...props} />}
              />
              <Route
                path="/products"
                excat
                component={(props) => <ProductsPage {...props} />}
              />
              <Route
                path="/services"
                component={(props) => <ServicesPage {...props} />}
              />
              <Route
                path="/opening-hours"
                component={(props) => <OpeningHoursPage />}
              />
              <Route path="/contact" component={(props) => <ContactPage />} />
              <Route
                path="/"
                exact
                component={(props) => <ProductsPage {...props} />}
              />
            </Switch>
          </main>
          <Footer />
        </div>
      </Router>
    </StoreProvider>
  );
}

export default App;
