import React from "react";

const OpeningHoursPage = () => {
  const today = new Date();
  return (
    <div className="container my-5 py-4 bg-white">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-sm-12 col-sm-12">
          <div className="h4 my-5 d-flex align-items-center">
            Horaire d'ouverture :{" "}
            <span
              className={[
                "lead ml-2",
                isOpenNow() ? "text-success" : "text-danger"
              ].join(" ")}
            >
              <b>(actuellement {isOpenNow() ? "ouvert" : "fermé"})</b>
            </span>
          </div>

          <table className="table">
            <tbody>
              {timing.map(day => (
                <tr
                  className={day.dayOfWeek === today.getDay() ? "bg-light" : ""}
                  key={day.day}
                >
                  <th>{day.day}</th>
                  <th>
                    {day.morning &&
                      `de ${day.morning.from}:00 à ${day.morning.to}:00`}
                    {!day.morning && <span className="text-danger">fermé</span>}
                  </th>
                  <th>
                    {day.evening &&
                      `de ${day.evening.from}:00 à ${day.evening.to}:00`}
                    {!day.evening && <span className="text-danger">fermé</span>}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const isOpenNow = () => {
  const now = new Date();
  const dayIndex = timing.findIndex(x => x.dayOfWeek === now.getDay());
  const dayTiming = timing[dayIndex];
  return (
    (dayTiming.morning &&
      dayTiming.morning.from <= now.getHours() &&
      dayTiming.morning.to > now.getHours()) ||
    (dayTiming.evening &&
      dayTiming.evening.from <= now.getHours() &&
      dayTiming.evening.to > now.getHours())
  );
};

const timing = [
  {
    day: "Samedi",
    morning: { from: 8, to: 12 },
    evening: { from: 14, to: 19 },
    dayOfWeek: 6
  },
  {
    day: "Dimanche",
    morning: { from: 8, to: 12 },
    evening: { from: 14, to: 19 },
    dayOfWeek: 0
  },
  {
    day: "Lundi",
    morning: { from: 8, to: 12 },
    evening: { from: 14, to: 19 },
    dayOfWeek: 1
  },
  {
    day: "Mardi",
    morning: { from: 8, to: 12 },
    evening: { from: 14, to: 19 },
    dayOfWeek: 2
  },
  {
    day: "Mercredi",
    morning: { from: 8, to: 12 },
    evening: { from: 14, to: 19 },
    dayOfWeek: 3
  },
  {
    day: "Jeudi",
    morning: { from: 8, to: 12 },
    evening: { from: 14, to: 19 },
    dayOfWeek: 4
  },
  {
    day: "Vendredi",
    dayOfWeek: 5
  }
];

export default OpeningHoursPage;
