import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import ReCAPTCHA from "react-google-recaptcha";

const OrderForm = () => {
  const { order, dirty, errors } = useStoreState((state) => state);
  const { progress, httpState } = order;
  const { onFormChange } = useStoreActions((actions) => ({
    onFormChange: actions.onFormChange,
  }));

  return (
    <form>
      <div className="p-3 row">
        <div className="col-12 pt-3 h4">
          <b>Entrez vos Information :</b>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label htmlFor="familyname">Nom</label>
            <input
              type="text"
              disabled={progress}
              className={getCssStyle(dirty, errors, "familyname")}
              id="familyname"
              value={order.body["familyname"] || ""}
              onChange={(e) => handleInputChange(e, "familyname", onFormChange)}
              placeholder="ex: Mohammed"
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label htmlFor="firstname">Prénom</label>
            <input
              type="text"
              disabled={progress}
              className={getCssStyle(dirty, errors, "firstname")}
              id="firstname"
              value={order.body["firstname"] || ""}
              onChange={(e) => handleInputChange(e, "firstname", onFormChange)}
              placeholder="ex: Mohammed"
            />
          </div>
        </div>
        {(canShowErrors(dirty, errors, "familyname") ||
          canShowErrors(dirty, errors, "firstname")) && (
          <div className="col-12">
            <div className="alert alert-danger">
              les champs 'Nom' et 'Prénom' doient être composés de <u>4</u> et{" "}
              <u>50</u>.
            </div>
          </div>
        )}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              disabled={progress}
              className={getCssStyle(dirty, errors, "email")}
              id="email"
              value={order.body["email"] || ""}
              onChange={(e) => handleInputChange(e, "email", onFormChange)}
              placeholder="exemple@pc27.dz"
            />
          </div>
        </div>
        {canShowErrors(dirty, errors, "email") && (
          <div className="col-12">
            <div className="alert alert-danger">
              Veuillez entrer une adresse mail valide.
            </div>
          </div>
        )}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="phone">Téléphone</label>
            <input
              type="phone"
              disabled={progress}
              className={getCssStyle(dirty, errors, "phone")}
              id="phone"
              value={order.body["phone"] || ""}
              onChange={(e) => handleInputChange(e, "phone", onFormChange)}
              placeholder="0777 888 999"
            />
          </div>
        </div>
        {canShowErrors(dirty, errors, "phone") && (
          <div className="col-12">
            <div className="alert alert-danger">
              Le champ 'Téléphone' doit être composé de <b>10</b> chiffres.
            </div>
          </div>
        )}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="address">Adresse</label>
            <textarea
              type="address"
              disabled={progress}
              className={getCssStyle(dirty, errors, "address")}
              id="address"
              value={order.body["address"] || ""}
              onChange={(e) => handleInputChange(e, "address", onFormChange)}
              rows="2"
            />
            {canShowErrors(dirty, errors, "address") && (
              <div className="col-12">
                <div className="alert alert-danger">
                  les champs 'Nom' et 'Prénom' doient être composés de <u>5</u>{" "}
                  et <u>255</u>.
                </div>
              </div>
            )}
          </div>

          <div className="form-group">
            <ReCAPTCHA
              sitekey="6LcgBfwUAAAAAFc1WN2P4GXVh-TobWdpT1ZpDCPD"
              onChange={(token) => {
                onFormChange({ key: "captcha", value: token });
              }}
            />
          </div>

          {httpState === "ERROR" && (
            <div className="alert alert-danger mt-2">
              Une érreur s'est produite, veuillez réessayer
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

const getCssStyle = (dirty, errors, key) => {
  return `form-control ${
    canShowErrors(dirty, errors, key) ? "is-invalid" : ""
  }`;
};

const canShowErrors = (dirty, errors, key) => {
  return dirty[key] && errors[key];
};

const handleInputChange = (e, key, action) => {
  e.persist();
  action({ key, value: e.target.value });
};

export default OrderForm;
