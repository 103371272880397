import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-input-range/lib/css/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import MessengerCustomerChat from "react-messenger-customer-chat";

ReactDOM.render(
  <React.Fragment>
    <MessengerCustomerChat
      pageId="1441383726133078"
      appId="2632145773717937"
      htmlRef="ref"
    />
    <App />
  </React.Fragment>,
  document.getElementById("root")
);

serviceWorker.unregister();
