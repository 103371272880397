import React, { useEffect } from "react";
import queryString from "query-string";
import InputRange from "react-input-range";
import { useStoreActions, useStoreState } from "easy-peasy";
import Pagination from "react-bootstrap/Pagination";
import Categories from "../components/categories";
import ProductsList from "../components/product.list";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Carousel from "react-material-ui-carousel";

const images = [
  "https://images-na.ssl-images-amazon.com/images/I/81R-fmF4SiL.jpg",
  "https://www.notebookcheck.net/fileadmin/Notebooks/News/_nc3/download13.jpeg",
  "https://cnet4.cbsistatic.com/img/dblsy71iOGux1xpy2FeQTsRz8hc=/1200x675/2020/03/04/a0107bc9-ad1d-4dcb-b453-9d053a1250dc/13-lenovo-yoga-c940-2-in-1.jpg",
];

const ProductsPage = (props) => {
  const { fetchProducts, showHideDialog, onFilterChange } = useStoreActions(
    (actions) => ({
      fetchProducts: actions.fetchProducts,
      showHideDialog: actions.showHideDialog,
      onFilterChange: actions.onFilterChange,
    })
  );
  const { http, filterDialog, filter } = useStoreState((state) => state);
  const { history, location } = props;
  const params = queryString.parse(location.search);
  const { category, pageSize, page, success, search, min, max, sort } = params;
  const currentPage = page ? +page : 1;
  const selectedCat = category ? category : "all";
  const { loadingProducts } = http;

  if (Object.keys(params).length === 0) {
    history.push("/?category=15");
  }

  const arr = Object.values(params);
  useEffect(() => {
    fetchProducts({
      category: selectedCat,
      search: search || "",
      pageIndex: currentPage - 1,
      min,
      max,
      pageSize: pageSize || 12,
    });
    onFilterChange({ key: "category", value: category || "all" });
    onFilterChange({ key: "min", value: min || "" });
    onFilterChange({ key: "max", value: max || "" });
    onFilterChange({ key: "sort", value: sort || "1" });
    // eslint-disable-next-line
  }, Object.values([...arr, ...new Array(10 - arr.length)]));

  return (
    <div className="container-fluid py-5">
      <div className="row justify-content-center">
        <div className="col-lg-3 col-md-4 col-sm-12 col-12 mb-2">
          <div className="filter-container">
            <Categories
              selectedId={selectedCat}
              navigateToCategory={(id) =>
                navigateToCategory(history, id, { showHideDialog })
              }
            />
            {!loadingProducts &&
              http.products.length > 0 &&
              filterInputs({
                divClasses: "p-1",
                filter,
                onFilterChange,
                history,
                min: http["min"],
                max: http["max"],
                showHideDialog,
              })}
          </div>
          <div className="filter-dialog">
            <Button
              variant="contained"
              color="secondary"
              startIcon={<FilterListIcon />}
              onClick={() => showHideDialog(true)}
            >
              Filtrer
            </Button>
            <Dialog
              open={filterDialog}
              onClose={() => {
                showHideDialog(false);
              }}
              fullWidth={true}
              style={{
                overflowY: "hidden",
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Filtrer les produits
              </DialogTitle>
              <DialogContent
                style={{
                  overflowY: "hidden",
                }}
              >
                {filterInputs({
                  divClasses: "p-1",
                  categories: http.categories,
                  filter,
                  onFilterChange,
                  history,
                  min: http["min"],
                  max: http["max"],
                  showHideDialog,
                })}
              </DialogContent>
            </Dialog>
          </div>
        </div>

        <div className="col-lg-9 col-md-8 col-sm-12 col-12 container">
          <Carousel
            className="mb-2"
            animation="slide"
            interval={5000}
            autoPlay={true}
            indicators={false}
          >
            {images.map((img, index) => (
              <div
                key={index}
                style={{
                  height: "320px",
                  width: "100%",
                  backgroundImage: `url(${img})`,
                  backgroundPosition: "center center",
                }}
              ></div>
            ))}
          </Carousel>
          <ProductsList
            navigateToDetail={(id) => navigateToProduct(history, id)}
          />

          {http.products && Array.isArray(http.products) && (
            <Pagination className="justify-content-end pc27-cl-blue">
              <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() =>
                  navigateToCategory(history, selectedCat, {
                    page: currentPage - 1,
                    showHideDialog,
                  })
                }
              />
              <Pagination.Item active>{currentPage}</Pagination.Item>
              <Pagination.Next
                disabled={!http.canGoNext}
                onClick={() =>
                  navigateToCategory(history, selectedCat, {
                    page: currentPage + 1,
                    showHideDialog,
                  })
                }
              />
            </Pagination>
          )}
        </div>
      </div>
      <Dialog
        open={success === "true"}
        onClose={() => {
          history.push("/");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Bravo !!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Votre commande a été bien envoyer, notre commercial va vous
            contacter dans le bref délait.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              history.push("/");
            }}
            color="primary"
            autoFocus
          >
            Terminer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const filterInputs = ({
  divClasses,
  categories = [],
  filter = {},
  onFilterChange,
  history,
  min = 0,
  max = 1000000,
  showHideDialog,
}) => {
  return (
    <React.Fragment>
      <select
        className="form-control rounded-0 filter-dialog"
        value={filter["category"] || "all"}
        onChange={(e) =>
          onFilterChange({
            key: "category",
            value: e.target.value,
          })
        }
      >
        {categories.map((item) => (
          <option key={item.id} value={item.id}>
            {item.category}
          </option>
        ))}
      </select>
      <div className={`${divClasses} mb-2 mt-4 mx-3`}>
        <InputRange
          maxValue={max === min ? max + 1 : max}
          minValue={min}
          formatLabel={(value, type) => `${value} DZD`}
          value={{ min: filter["min"] || min, max: filter["max"] || max }}
          onChange={(value) => {
            onFilterChange({ key: "min", value: value["min"] });
            onFilterChange({ key: "max", value: value["max"] });
          }}
        />
      </div>

      <div className={`${divClasses} mt-3`}>
        <Button
          variant="outlined"
          className="w-100"
          color="primary"
          startIcon={<SearchIcon />}
          onClick={() => {
            showHideDialog(false);
            history.push(`/?${queryString.stringify(filter)}`);
          }}
        >
          Rechercher
        </Button>
      </div>
    </React.Fragment>
  );
};

const navigateToProduct = (history, id) => {
  window.open(`/products/details/${id}`);
};

const navigateToCategory = (
  history,
  id,
  { page = 1, showHideDialog = null }
) => {
  history.push(`/products?category=${id}&page=${page}&pageSize=12`);
  if (showHideDialog) showHideDialog(false);
};

export default ProductsPage;
