import React, { Component } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faPhoneAlt,
  faBuilding,
  faClock,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "mapbox-gl/dist/mapbox-gl.css";

class Footer extends Component {
  state = {
    viewport: {
      width: "100%",
      height: "250px",
      latitude: 35.9185477,
      longitude: 0.0600673,
      zoom: 10,
    },
    marker: {
      latitude: 35.9185477,
      longitude: 0.0600673,
    },
  };
  render() {
    const { viewport, marker } = this.state;

    return (
      <footer className="text-white pc27-bg-blue">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-5 mb-2">
              <div className="mb-2">
                <FontAwesomeIcon className="mr-2" icon={faPhoneAlt} />
                0795 00 27 27/ 045 35 14 41
              </div>
              <div className="mb-2">
                <FontAwesomeIcon className="mr-2" icon={faAt} />
                commercial@pc27services.com
              </div>
              <div className="mb-2">
                <FontAwesomeIcon className="mr-2" icon={faBuilding} />
                27000, Boulevard de la Salamandre, Mostaganem
              </div>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon className="mr-2" icon={faClock} />
                <div className="ml-2">
                  Ouvert du Samedi au Jeudi <br />
                  de 08:00 à 12:00 | 14:00 à 19:00
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <ReactMapGL
                {...viewport}
                onClick={this._handleMapClick}
                mapStyle="mapbox://styles/mapbox/streets-v8"
                mapboxApiAccessToken="pk.eyJ1IjoiYWJkZXJyYWhtZW5lIiwiYSI6ImNrNm0zZ3dzaTBqeXEzcnA2YmIzanQwYWoifQ.AMU3n95UOPCc6xv6vmYYaQ"
              >
                <Marker latitude={marker.latitude} longitude={marker.longitude}>
                  <FontAwesomeIcon
                    onClick={this._handleMapClick}
                    className="pc27-cl-red"
                    size="3x"
                    icon={faMapMarkerAlt}
                  />
                </Marker>
              </ReactMapGL>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  _handleMapClick = () => {
    window.open(
      "https://www.google.com/maps/place/PC27+Services/@35.9185477,0.0600673,17z/data=!3m1!4b1!4m5!3m4!1s0x128201e3b08e053b:0xcdb64ee05e774857!8m2!3d35.9185434!4d0.062256"
    );
  };
}

export default Footer;
