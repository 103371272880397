import React from "react";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import { KeyboardArrowRight } from "@material-ui/icons";
import { useStoreState } from "easy-peasy";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";

const Categories = ({ selectedId, navigateToCategory }) => {
  const { http } = useStoreState((state) => state);
  const { categories, loadingCategories } = http;
  const exprendedIds = shouldBeExpended(categories, selectedId);
  return (
    <Card elevation={0}>
      <List className="py-0">
        {loadingCategories &&
          [1, 2, 3, 4, 5, 6].map((index) => (
            <React.Fragment key={index}>
              <div className="py-1">
                <div className="py-3 bg-progress"></div>
              </div>
              <Divider />
            </React.Fragment>
          ))}
        {!loadingCategories &&
          categories
            .filter((item) => +item.parent === 0)
            .map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {generateMenuItem(
                    item,
                    categories,
                    selectedId,
                    navigateToCategory,
                    exprendedIds
                  )}
                  <Divider />
                </React.Fragment>
              );
            })}
      </List>
    </Card>
  );
};

const generateMenuItem = (
  item,
  categories,
  selectedId,
  navigateToCategory,
  exprendedIds
) => {
  const items = categories.filter((elt) => +elt.parent === +item.id);
  if (items.length === 0)
    return (
      <ListItem
        button
        className="p-2 text-left"
        selected={selectedId === item.id}
        onClick={() => navigateToCategory(item.id)}
      >
        <ListItemText
          className={
            +item.parent === 0 && selectedId !== item.id
              ? "font-weight-bold pc27-cl-blue"
              : ""
          }
          primary={item.category}
        />
        <ListItemSecondaryAction>
          <KeyboardArrowRight
            style={{
              color: selectedId === item.id ? "white" : "black",
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );

  return (
    <ExpansionPanel
      defaultExpanded={exprendedIds.includes(item.id)}
      className="p-0"
      elevation={0}
    >
      <ExpansionPanelSummary
        className="px-2 text-decoration-underline"
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: "black",
            }}
          />
        }
      >
        <Button
          className={
            +item.parent === 0
              ? "font-weight-bold pc27-cl-blue p-0 text-left"
              : "p-0 text-left"
          }
          onClick={() => navigateToCategory(item.id)}
          color="primary"
        >
          {item.category}
        </Button>
        <Divider />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="p-0">
        <List className="p-0 w-100">
          {items.map((elt, index) => {
            return (
              <React.Fragment key={index}>
                {generateMenuItem(
                  elt,
                  categories,
                  selectedId,
                  navigateToCategory
                )}
                {index + 1 < items.length && <Divider />}
              </React.Fragment>
            );
          })}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const shouldBeExpended = (categories, selectedId) => {
  let item = categories.find((elt) => elt.id === selectedId);
  if (!item) return [];

  const ids = [];
  ids.push(item.id);

  let cat = item;
  while (cat && cat.parent !== "0") {
    // eslint-disable-next-line
    cat = [...categories].find((elt) => elt.id === cat.parent);
    if (cat) ids.push(cat.id);
  }

  return ids;
};
export default Categories;
