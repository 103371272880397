import React from "react";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import CurrencyFormat from "react-currency-format";

const ProductItem = (props) => {
  try {
    const { product, onDetailClicked, addToList, items } = props;

    const {
      id,
      title,
      img_path,
      photo,
      price,
      promo,
      promoPrice,
      ref,
      soldOut,
    } = product;

    const index = items.findIndex(({ productId }) => productId === id);
    const quantity = index < 0 ? 0 : items[index].quantity;

    return (
      <div className="product-card w-100 h-100 d-inline-block border border-light rounded-sm  p-2">
        <div>
          {getContentTemplate(title, `${img_path}${photo}`)}
          {(+price !== 0 || +promoPrice !== 0) &&
            getPriceTemplate(price, promo, promoPrice, ref)}
        </div>
        {promo === "1" && (
          <div className="promo-over pc27-bg-red rounded-circle d-flex align-items-center">
            <span className="mx-auto">
              {parseInt(100 - (promoPrice / price) * 100)}%
            </span>
          </div>
        )}
        {+soldOut === 1 && (
          <div className="out-of-stock-over d-flex align-items-center">
            <div className="w-100 p-2 text-container text-center">
              Stock Epuisé
            </div>
          </div>
        )}
        {quantity > 0 && (
          <div className="quantity-over d-flex align-items-center">
            <Badge className="ml-2" badgeContent={quantity} color="secondary">
              <FontAwesomeIcon icon={faShoppingBasket} />
            </Badge>
          </div>
        )}
        <div className="focus-card d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column p-4 w-100">
            <Button
              variant="contained"
              color="primary"
              className="w-100 pc27-bg-blue text-white"
              onClick={() =>
                addToShopList(addToList, {
                  id,
                  price,
                  promo,
                  promoPrice,
                  title,
                  photo: `${img_path}${photo}`,
                })
              }
            >
              Ajouter au panier
              {quantity > 0 && (
                <Badge
                  className="ml-2"
                  badgeContent={quantity}
                  color="secondary"
                >
                  <FontAwesomeIcon icon={faShoppingBasket} />
                </Badge>
              )}
            </Button>
            <Button
              className="mt-2 w-100"
              variant="contained"
              color="secondary"
              onClick={() => onDetailClicked(id)}
            >
              Détail
            </Button>
          </div>
        </div>
      </div>
    );
  } catch (error) {
    console.error(error);
    return (
      <div className="alert alert-danger">item product rendering failed</div>
    );
  }
};
const addToShopList = (
  addToList,
  { id, price, promo, promoPrice, title, photo }
) => {
  addToList({
    productId: id,
    price: promo === "1" ? promoPrice : price,
    title,
    photo,
  });
};

const getContentTemplate = (title, photo) => {
  return (
    <div className="product-info">
      <img src={photo} alt={title} />
      <div className="title text-justify pt-2">
        <br />
        {title.length < 35 ? title : title.substring(0, 35) + "..."}
      </div>
    </div>
  );
};

const getPriceTemplate = (price, promo, promoPrice, ref) => {
  return (
    <div className="d-flex price-holder pt-1">
      <div>
        <span
          className={
            promo === "1" ? "ml-1 promo-theme text-secondary" : "pc27-cl-red"
          }
        >
          <CurrencyFormat
            value={price}
            displayType={"text"}
            thousandSeparator={true}
          />
          DZD
        </span>

        {promo === "1" && (
          <React.Fragment>
            <br />
            <span className="pc27-cl-red">
              <CurrencyFormat
                value={promoPrice}
                displayType={"text"}
                thousandSeparator={true}
              />
            </span>{" "}
            DZD
          </React.Fragment>
        )}
      </div>
      <div className="ml-auto text-success">Ref: {ref}</div>
    </div>
  );
};

export default ProductItem;
